// import { tns } from "tiny-slider/src/tiny-slider.module"
import { tns } from 'tiny-slider/src/tiny-slider';
// import vhCheck from 'vh-check'
// vhCheck()

import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();


// viewport fix

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
var vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// var resizeTimer;

window.addEventListener('resize', resizeCallback);
function resizeCallback() {
  // clearTimeout(resizeTimer);
  window.setTimeout(function() {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // Run code here, resizing has "stopped"
            
  }, 66);
};


// slider logic

// projects slider

var projectsSection   = document.querySelector('#projects');
var projectsContainer = document.querySelectorAll('.projects-slider');

// var prev              = document.querySelector('#prev-all');
// var next              = document.querySelector('#next-all');
var down              = document.querySelector('#down-button');

function moveDown(e) {
  e.preventDefault();
  document.querySelector('#projects').scrollIntoView({ behavior: 'smooth' });
}
if (down) {
  down.addEventListener('click', moveDown);
}

if (projectsContainer.length) {
  projectsContainer.forEach(function (item) {
    var projectsSlider;
    // var controls = false;
    var prev = false;
    var next = false;
    var itemsChildren = item.querySelectorAll('.projects-slider-item');
    var itemsChildrenLength = itemsChildren.length;
    var decide = itemsChildrenLength == 1 ? false : true;
    if (decide) {
      // controls = item.parentElement.querySelector('.controls');
      prev     = item.parentElement.querySelector('.prev');
      next     = item.parentElement.querySelector('.next');
      prev.onclick = function () {
        projectsSlider.goTo('prev');
      }
      next.onclick = function () {
        projectsSlider.goTo('next');
      }
    }
    projectsSlider = tns({
      container: item,
      controls: false,
      nav: false,
      arrowKeys: true,
      mouseDrag: true,
      swipeAngle: 30,
      preventScrollOnTouch: "auto",
      slideBy: 1,
      items: 1,
      responsive: {
        800: {
          items: 2
        },
        1600: {
          items: 2
        }
      },
      gutter: 10,
      autoHeight: false,
      center: false,
      loop: decide,
      rewind: false,
      speed: 400,
      edgePadding: 0,
    });

    var info = projectsSlider.getInfo();
    projectsSlider.events.on('transitionStart', function () {
      info.container.classList.add('is-animating');
    });
    projectsSlider.events.on('transitionEnd', function () {
      info.container.classList.remove('is-animating');
    });
    //  for (var i = 0; i < info.slideItems.length; i++) {
    //    info.slideItems[i].querySelector('.projects-cover').onclick = function () {
    //        projectsSlider.goTo('next');
    //    }
    //  }
  })
}


//var menuItems = document.querySelectorAll('.menu-item');
//var menuItemProjekte = document.querySelector('.menu-item-projekte')
function clickHandler (e) {
  var target = e.target;
//  if (target.matches('.project-summary')) {
//    if (projectsSection.matches('.hidden-details')) {
//      projectsSection.classList.remove('hidden-details');
//    } else {
//      projectsSection.classList.add('hidden-details');
//    }
//  }
  if (target.matches('.category-toggle')) {
    setTimeout(function () {
      target.scrollIntoView({behavior: "smooth"});
    }, 400)
  }
  //  if (target.matches('.projects-toggle')) {
  //    menuItemProjekte.classList.toggle('toggled');
  //  }

  //  if (!target.closest('.menu-item-projekte')) {
  //    menuItemProjekte.classList.remove('toggled');
  //  }
  
  if (target.matches('.category-toggle')) {
//   var menuItem = target.closest('.menu-item');
//   if (menuItem) {
//     var menuItemLink = menuItem.querySelector('.menu-item-link');
//     // console.log(menuItemLink.href);
//     window.location = menuItemLink.href;

//   }
    var value = target.value;
    var projectID = 'projects-slider-' + value;
    var currentProject = document.getElementById(projectID);
    var projectWrapperID = 'projects-wrapper-' + value;
    var currentProjectWrapper = document.getElementById(projectWrapperID);
    //console.log(currentProject);
    if (value.length) {
      // destroy the slider
      for (var i = 0; i < projectsContainer.length; i++) {
        if (!projectsContainer[i].closest('.projects-wrapper').matches('.hidden')) {
          projectsContainer[i].closest('.projects-wrapper').classList.add('hidden');
          projectsContainer[i].classList.add('hidden');
        }
      }
      if (currentProjectWrapper) {
        currentProject.classList.remove('hidden');
        currentProjectWrapper.classList.remove('hidden');
      }
    }
  }
}

  document.addEventListener('click', clickHandler);


// feature slider

var featureContainer = document.querySelectorAll('.feature-slider');

if (featureContainer.length) {
  featureContainer.forEach(function (item) {
    var featureSlider = tns({
      container: item,
      controls: false,
      nav: false,
      arrowKeys: true,
      mouseDrag: true,
      items: 1,
      slideBy: 1,
      gutter: 0,
      edgePadding: 1,
      autoHeight: false,
      loop: true,
      rewind: false,
      speed: 400,
      swipeAngle: false,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 7000
    });

      var info = featureSlider.getInfo();
      featureSlider.events.on('transitionStart', function () {
        info.container.classList.add('is-animating');
      });
      featureSlider.events.on('transitionEnd', function () {
        info.container.classList.remove('is-animating');
      });
      for (var i = 0; i < info.slideItems.length; i++) {
        info.slideItems[i].onclick = function () {
          featureSlider.goTo('next');
        }
      }
    })
}


// project slider

var featureContainer = document.querySelectorAll('.project-gallery-slider');

if (featureContainer.length) {
  featureContainer.forEach(function (item) {
    // var controls = false;
    var featureSlider;
    var prev = false;
    var next = false;
    var itemsChildren = item.querySelectorAll('.project-gallery-item');
    var itemsChildrenLength = itemsChildren.length;
    var decide = itemsChildrenLength == 1 ? false : true;
    if (decide) {
      // controls = item.parentElement.querySelector('.controls');
      prev     = item.parentElement.querySelector('.prev');
      next     = item.parentElement.querySelector('.next');
      prev.onclick = function () {
        featureSlider.goTo('prev');
      }
      next.onclick = function () {
        featureSlider.goTo('next');
      }
    }
    featureSlider = tns({
      container: item,
      controls: false,
      nav: false,
      arrowKeys: false,
      mouseDrag: true,
      items: 1,
      responsive: {
        800: {
          items: 1.5,
          center: false
        },
        1600: {
          items: 2,
          center: false
        }
      },
      gutter: 10,
      autoHeight: false,
      center: true,
      loop: decide,
      speed: 400,
      swipeAngle: false,
    });

      var info = featureSlider.getInfo();
      featureSlider.events.on('transitionStart', function () {
        info.container.classList.add('is-animating');
      });
      featureSlider.events.on('transitionEnd', function () {
        info.container.classList.remove('is-animating');
      });
//      for (var i = 0; i < info.slideItems.length; i++) {
//        info.slideItems[i].onclick = function () {
//          featureSlider.goTo('next');
//        }
//      }
    })
}